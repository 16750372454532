import React from 'react'
import Navigation from "../Navigation";
import openDetailImg from "../../assets/images/article/open_detail.png";

function ShareArticle() {
  return (
    <>
    <div className="flex my-8 relative">
        <div className="w-[100%] xl:w-[66.66%] px-[24px]">
            <h1 className="mb-3 heading">Share your Article</h1>
            <p className="my-2">
            If you want to <strong>Share</strong> your article on social media platforms, Please simply copy the link provided for the <strong>Article ID</strong> and paste it on your chosen platform to share with others.
                                   
            </p>
            <img src={openDetailImg} alt="article-detail"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
            <Navigation
                previous={"Previous: Edit an Article"}
                previousNav={"edit-article"} next={"Next: Search an Article"} nextNav={"search-article"}
            />
        </div>

        <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
            {/* <p className='font-semibold my-8 '>On this page</p>
<ul className='border-t-2 py-4'>
<li className='link-list '><a href="#introduction-to-models" className='links'>Introduction to Models</a></li>
</ul> */}
        </div>
    </div>
</>
  )
}

export default ShareArticle
