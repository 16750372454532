import React from "react";
import Navigation from "../Navigation";
import articleDetailImg from "../../assets/images/article/article_detail.png";
import articleNavBarImg from "../../assets/images/article/article_navbar.png";
import createArticleImg from "../../assets/images/article/create_article.png";
import navbarCreateImg from "../../assets/images/article/navbar_create.png";
import navbarProfileImg from "../../assets/images/article/navbar_profile.png";
import navbarSignupImg from "../../assets/images/article/navbar_signup.png";
import signupImg from "../../assets/images/article/signup.png";

function CreateArticle() {
    return (
        <>
            <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                    <h1 className="mb-3 heading">Create an Article</h1>
                    <h2 className="sub-heading">Article</h2>
                    <p className="my-2">All Users can create and publish articles showcasing their AI knowledge and expertise, which are shared with their followers. These articles can also be easily shared across social media platforms.
</p>
                    <div className="my-6" id="create-an-article">
                        <h2 className="sub-heading">Create an article</h2>
                        <p className="my-2 font-semibold">
                            To create an article on Vipas.AI, follow these
                            steps:
                        </p>
                        <ul>
                            <li className="link-list">
                                <strong>Sign Up</strong>: Visit{" "}
                                <a href="https://vipas.ai" className="links">
                                    Vipas.AI
                                </a>{" "}
                                and register for an account.
                                <img
                                    src={navbarSignupImg}
                                    alt="navbar-signup"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                                <img
                                    src={signupImg}
                                    alt="signup"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                            </li>

                            <li className="link-list">
                                <strong>Access the 'Create' Option</strong>:
                                After logging in, click on the 'Create' button,
                                From the dropdown, choose 'Article' to begin
                                drafting.
                                <img
                                    src={navbarCreateImg}
                                    alt="navbar-create"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                                <img
                                    src={articleNavBarImg}
                                    alt="navbar-article"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                            </li>
                            <li className="">
                                <strong>Enter Article Details</strong>:
                                <ul>
                                    <li className="link-list">
                                        <strong>Title</strong>: Provide a clear
                                        and concise title for your article.
                                    </li>

                                    <li className="link-list">
                                        <strong>Category</strong>: Select the
                                        appropriate category.{" "}
                                    </li>
                                    <li className="link-list">
                                        <strong>External URL</strong>: Including
                                        external URL is optional, but it can be
                                        valuable for redirecting readers to your
                                        external website.
                                    </li>
                                    <li className="link-list">
                                        <strong>Article image</strong>: Upload
                                        an image that represents your article's
                                        content.
                                    </li>
                                </ul>
                                <img
                                    src={createArticleImg}
                                    alt="create-article"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                            </li>

                            <li className="link-list">
                                <strong>Set Visibility</strong>: Decide whether
                                your article will be 'Public' or 'Private'.
                                <ul className="">
                                    <li className="link-list">
                                        <strong>Public</strong>: When you choose
                                        the 'Public' option, your article will
                                        be accessible to all viewers. This means
                                        anyone browsing the article can read and
                                        engage with your content.
                                    </li>
                                    <li className="link-list">
                                        <strong>Private</strong>: Selecting the
                                        'Private' option ensures that only the
                                        creator can view the article.
                                    </li>
                                </ul>
                            </li>
                            <li className="link-list">
                                <strong>Write a Description</strong>: In up to
                                60 characters, summarize your article's content.
                            </li>
                            <li className="link-list">
                                <strong>Compose the Article</strong>: Begin
                                writing your article in detail. Ensure it's
                                well-structured and engaging.
                            </li>
                            <li className="link-list">
                                <strong>Publish</strong>: Once your article is
                                complete, you can proceed by clicking the Create
                                button to publish it.
                                <img
                                    src={articleDetailImg}
                                    alt="article-detail-create"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                                <p className="font-semibold">
                                    After publishing your article, if you want
                                    to check:
                                </p>
                                <p>
                                    Navigate to your profile in the top right
                                    corner of the home menu and select the '
                                    <strong>Profile</strong>' tab.
                                </p>
                                <img
                                    src={navbarProfileImg}
                                    alt="navbar-profile"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                            </li>
                            {/* <li className="ml-4">
                                Go to <strong>Manage Projects</strong>.
                                <img
                                    src={navbarManageImg}
                                    alt="navbar-manage"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                                <ul>
                                    <li>Select the Article option to view your published article.</li>
                                    <img
                                    src={manageArticleImg}
                                    alt="manage-article"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                                    <li>Choose the <strong>Open</strong> option to view your published article.</li>
                                    <img
                                    src={articleOpenImg}
                                    alt="article-open"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                /> */}
                                    {/* <li>Use the <strong>Edit</strong> option if you want to make any changes to your article.</li>
                                    <img
                                    src={manageEditImg}
                                    alt="article-edit"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                /> */}
                                    {/* <li>If you want to <strong>Share</strong> your article on social media platforms, Please simply copy the link provided for the <strong>Article ID</strong> and paste it on your chosen platform to share with others.
                                    <img src={openDetailImg} alt="article-detail"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />
                                    </li> */}

                                {/* </ul>
                            </li> */}
                            {/* <li className="ml-4">
                            To search for your article on the Vipas.AI:

                                <ul>
                                    <li>Simply type the title of your article in the <strong>Search</strong> box.</li>
                                    <li>This will help you quickly find and view your article as demonstrated in the image above.</li>
                                    <img src={navbarSearchImg}   alt="navbar-search" loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"/>
                                </ul>
                            </li> */}
                        </ul>
                    </div>

                    <Navigation
                        previous={"Previous: Adding Balance to Your Account"}
                        previousNav={"add-balance"} next={"Next: Manage Articles"} nextNav={"manage-articles"}
                    />
                </div>

                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                    {/* <p className='font-semibold my-8 '>On this page</p>
        <ul className='border-t-2 py-4'>
          <li className='link-list '><a href="#introduction-to-models" className='links'>Introduction to Models</a></li>
        </ul> */}
                </div>
            </div>
        </>
    );
}

export default CreateArticle;
