import React from 'react'
import Navigation from "../Navigation";
import manageEditImg from "../../assets/images/article/manage_edit.png";

function EditArticle() {
  return (
    <>
    <div className="flex my-8 relative">
        <div className="w-[100%] xl:w-[66.66%] px-[24px]">
            <h1 className="mb-3 heading">Edit an Article</h1>
            <p className="my-2">
                Use the <strong>Edit</strong> option if you want to make any changes to your article.                  
            </p>
            <img src={manageEditImg}
                                    alt="article-edit"
                                    loading="lazy"
                                    className="my-6 tw-w-full tw-h-auto tw-object-contain"
                                />

            <Navigation previous={"Previous: Manage Articles"} previousNav={"manage-articles"} next={"Next: Share your Article"} nextNav={"share-article"}
            />
        </div>

        <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
            {/* <p className='font-semibold my-8 '>On this page</p>
<ul className='border-t-2 py-4'>
<li className='link-list '><a href="#introduction-to-models" className='links'>Introduction to Models</a></li>
</ul> */}
        </div>
    </div>
</>
  )
}

export default EditArticle
