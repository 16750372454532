import React from "react";
import navbarSearchImg from "../../assets/images/article/navbar_search.png";
import Navigation from "../Navigation";

function SearchArticle() {
    return (
        <>
            <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                    <h1 className="mb-3 heading">Search an Article</h1>
                    <p className="my-2">
                        To search for your article on the Vipas.AI:
                    </p>
                    <ul>
                        <li className="link-list">
                            Simply type the title of your article in the{" "}
                            <strong>Search</strong> box.
                        </li>
                        <li className="link-list">
                            This will help you quickly find and view your
                            article as demonstrated in the image above.
                        </li>
                        <img
                            src={navbarSearchImg}
                            alt="navbar-search"
                            loading="lazy"
                            className="my-6 tw-w-full tw-h-auto tw-object-contain"
                        />
                    </ul>
                    <Navigation
                        previous={"Previous: Share your Article"}
                        previousNav={"share-article"}     
                    />
                </div>

                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                    {/* <p className='font-semibold my-8 '>On this page</p>
<ul className='border-t-2 py-4'>
<li className='link-list '><a href="#introduction-to-models" className='links'>Introduction to Models</a></li>
</ul> */}
                </div>
            </div>
        </>
    );
}

export default SearchArticle;
