import React from 'react'
import Navigation from "../Navigation";
import manageArticleImg from "../../assets/images/article/manage_article.png";
import articleOpenImg from "../../assets/images/article/article_open.png";
import navbarManageImg from "../../assets/images/article/navbar_manage.png";
function ManageArticles() {
    return (
        <>
            <div className="flex my-8 relative">
                <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                    <h1 className="mb-3 heading">Manage an Articles</h1>
                    <ul>
                        <li className="link-list">Go to <strong>Manage Projects</strong>.
                        </li>
                        <img
                            src={navbarManageImg}
                            alt="navbar-manage"
                            loading="lazy"
                            className="my-6 tw-w-full tw-h-auto tw-object-contain"
                        />

                        <li className='link-list' >Select the Article option to view your published article.</li>
                        <img
                            src={manageArticleImg}
                            alt="manage-article"
                            loading="lazy"
                            className="my-6 tw-w-full tw-h-auto tw-object-contain"
                        />
                        <li className='link-list' >Choose the <strong>Open</strong> option to view your published article.</li>
                        <img
                            src={articleOpenImg}
                            alt="article-open"
                            loading="lazy"
                            className="my-6 tw-w-full tw-h-auto tw-object-contain"
                        />
                    </ul>
                    <Navigation
                        previous={"Previous: Create an Article"}
                        previousNav={"create-article"} next={"Next: Edit an Article"} nextNav={"edit-article"}
                    />
                </div>

                <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                    {/* <p className='font-semibold my-8 '>On this page</p>
<ul className='border-t-2 py-4'>
<li className='link-list '><a href="#introduction-to-models" className='links'>Introduction to Models</a></li>
</ul> */}
                </div>
            </div>
        </>
    )
}

export default ManageArticles
