import React from "react";
import { CopyBlock, anOldHope } from "react-code-blocks";
import Navigation from "../Navigation";
import SEOHelmet from '../../utils/SEOHelmet';


function PublishSDK() {
    const codeSnippets = `import vipas
vipas.model.ModelClient.publish(model_id: str,
model_folder_path: str, model_framework_type: str,
onnx_config_path: Optional[str] = None, processor_folder_path: Optional[str] = None,
processor_image: Optional[str] = None, auto_launch: bool = True,
override_model: bool = True) → dict`;

const exampleSnippet=`from vipas.model import ModelClient
from vipas.exceptions import UnauthorizedException, NotFoundException, ClientException


# Paths to model and processor files
model_folder_path = "/path/to/your/model"
onnx_config_path = "/path/to/config/config.pbtxt"  # Optional, depends on framework
processor_folder_path = "/path/to/your/processor"

# Unique model ID to identify the model in Vipas.AI
model_id = "your_model_id" # mdl-xxxxxxxxx

try:
    # Initialize the ModelClient
    model_client = ModelClient()

    # Publish the model
    model_client.publish(
        model_id=model_id,
        model_folder_path=model_folder_path,
        model_framework_type="tensorflow",  # Supported: tensorflow, pytorch, onnx, xgboost, sklearn.
        onnx_config_path=onnx_config_path,  # Required for the ONNX model framework        
        processor_folder_path=processor_folder_path,  # Optional if using custom processors
        processor_image="your-processor-image:latest",  # allowed value are [“vps-processor-base:1.0”]
        auto_launch=True,  # Whether to automatically launch the model after upload, Default True
        override_model=True  # Whether to override existing model deployments, Default True
    )
except UnauthorizedException as e:
    print(f"UnauthorizedException: {e}")
except NotFoundException as e:
    print(f"NotFoundException: {e}")
except ClientException as e:
    print(f"ClientException: {e}")
except Exception as e:
    print(f"Exception: {e}")`
    return (
        <>
        <div className="flex my-8 relative">
            <div className="w-[100%] xl:w-[66.66%] px-[24px]" id="basic-usage">
                <h1 className="mb-3 heading" id='vipas-sdk'>Publishing Model</h1>
                <p className="my-2">
                    The{" "}
                    <a
                        href="https://pypi.org/project/vipas/"
                        className="links !text-[16px]"
                    >
                        Vipas.AI SDK
                    </a>{" "}
                    provides a simple and powerful interface for developers to
                    publish, manage, and deploy AI models. With this SDK,
                    developers can upload their models, configure model
                    processors, and deploy them to the Vipas platform
                    seamlessly. This documentation will guide you through the
                    process of using the SDK to publish and manage models built
                    on various machine learning frameworks, including
                    TensorFlow, PyTorch, ONNX, XGBoost, Scikit-learn, and more.
                </p>

                <div className="my-6" id='syntax'>
                    <h2 className="sub-heading">Syntax</h2>
                    <p className="font-semibold">
                        Import the necessary modules:
                    </p>
                    <div className="my-4 w-full">
                        <CopyBlock
                            text={codeSnippets}
                            language="python"
                            showLineNumbers={false}
                            theme={anOldHope}
                            wrapLines={true}
                            codeBlock
                        />
                    </div>
                    <p className="my-2">
                    Publish a model to the Vipas AI platform.
                </p> 
                </div>
                <div className="my-6">
                    <h2 className="sub-heading" id='parameters'>Parameters</h2>
                    <ul>
                        <li className="link-list">
                            <code>model_id (str)</code>: The unique identifier
                            of the model.
                        </li>
                        <li className="link-list">
                            <code>model_folder_path (str)</code>: The path to
                            the folder containing the model files.
                        </li>
                        <li className="link-list">
                            <code>model_framework_type (str)</code>: The
                            framework type of the model (‘tensorflow’,’
                            pytorch’, ‘onnx’, ‘sklearn’, ‘xgboost’).
                        </li>
                        <li className="link-list">
                            <code>onnx_config_path (Optional[str])</code>: The
                            path to the ONNX config file (if applicable).
                        </li>
                        <li className="link-list">
                            <code>processor_folder_path (Optional[str])</code>:
                            The path to the processor folder (if using a custom
                            processor).
                        </li>
                        <li className="link-list">
                            <code>processor_image (Optional[str])</code>: The
                            Docker image to use for the processor.
                        </li>
                        <li className="link-list">
                            <code>auto_launch (bool)</code>: Whether to
                            automatically launch the model after publishing
                            (default: True).
                        </li>
                        <li className="link-list">
                            <code>override_model (bool)</code>: Whether to
                            override the existing model (default: True).
                        </li>
                    </ul>
                </div>
                <div className="my-6" id='returns'>
                    <h2 className="sub-heading">Returns</h2>
                    <ul>
                        <li className="link-list">
                            <code>dict (str)</code>: A dictionary containing the
                            status and details of the model publishing process.
                        </li>
                    </ul>
                </div>

                <div className="my-6" id='allowed-model'>
                    <h2 className="sub-heading">Allowed model frameworks</h2>
                    <ul>
                        <li className="link-list">
                            <strong>TensorFlow</strong>: An open-source platform for machine learning, known for deep learning model development. Learn more about it <a href="" className="">here</a>. For file validations and directory structures, visit <a href="" className="">here</a>.
                            <p>model_framework_type: "<code>tensorflow</code>"</p>
                        </li>
                        <li className="link-list">
                            <strong>PyTorch</strong>: A flexible and efficient framework for building machine learning and deep learning models. Learn more about it here. For file validations and directory structures, visit here.
                            <p>model_framework_type: "<code>pytorch</code>"</p>

                        </li>
                        <li className="link-list">
                            <strong>ONNX</strong>: An open format built to represent machine learning models, enabling interoperability. Learn more about it here. For file validations and directory structures, visit here.
                            <p>model_framework_type: "<code>onnx</code>"</p>

                        </li>
                        <li className="link-list">
                            <strong>Sklearn</strong>: A simple and efficient tool for data mining and data analysis with machine learning algorithms. Learn more about it here. For file validations and directory structures, visit here.
                            <p>model_framework_type: "<code>sklearn</code>"</p>

                        </li>
                        <li className="link-list">
                            <strong>XGBoost</strong>: A scalable and fast implementation of gradient boosting for supervised learning tasks. Learn more about it here. For file validations and directory structures, visit here.
                            <p>model_framework_type: "<code>xgboost</code>"</p>
                        </li>
                    </ul>
                    <p className="my-2">Here is a basic example of how to use the SDK to publish a model from any remote environment:
</p>
<p className="my-2 font-semibold" id='code-example'>Example code for model publish
</p> 
<div className="my-4 w-full">
                        <CopyBlock
                            text={exampleSnippet}
                            language="python"
                            showLineNumbers={false}
                            theme={anOldHope}
                            wrapLines={true}
                            codeBlock
                        />
                    </div>
                </div>

               
                <Navigation
                    previous={
                        "Previous: Create Models"
                    }
                    previousNav={"vipas-sdk-create-model"}
                    next={"Next: Retrieve Model Deployment Logs"}
                    nextNav={"retrieve-model-deployment-logs"}
                />
            </div>
            <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
                <p className="font-semibold my-8 ">On this page</p>
                <ul className="border-t-2 py-4">
                    <li className="link-list ">
                        <a href="#vipas-sdk" className="links">
                            Vipas SDK
                        </a>
                    </li>
                    <li className="link-list ">
                        <a href="#syntax" className="links">
                            Syntax
                        </a>
                    </li>
                    <li className="link-list ">
                        <a href="#parameters" className="links">
                            Parameters
                        </a>
                    </li>
                    <li className="link-list ">
                        <a href="#returns" className="links">
                            Returns
                        </a>
                    </li>
                    <li className="link-list ">
                        <a href="#allowed-model" className="links">
                        Allowed model frameworks
                        </a>
                    </li>
                    <li className="link-list ">
                        <a href="#code-example" className="links">
                        Example code for model publish
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        </>
    );
}

export default PublishSDK;
